
import { defineComponent } from "vue-demi";
import { useAPI } from '@/use';
import { REGISTRATION_INPUTS } from '@/store/common/auth';
import CommonInputs from '@/components/common/inputs/index.vue';

export default defineComponent({
  name: 'RegistrationMain',
  data() {
    return {
      auth: REGISTRATION_INPUTS
    }
  },
  created() {
    if (this.$route.query.referral)
      this.auth.data.referral = String(this.$route.query.referral);
    if (this.$route.query.partner)
      this.auth.data.partner = true;
  },
  methods: {
    async registration() {
      await useAPI().auth.registrationMethod(this.auth.data);
      this.$router.push('/auth');
      this.$store.commit('createNotification', {
        message: 'Ваш аккаунт зарегистрирован!',
        status: 'success',
      });
      setTimeout(() => {
        this.$store.commit('createNotification', {
          message: 'Для активации аккаунта на Вашу почту было отправлено письмо с дальнейшей инструкцией!',
          status: 'success',
        });
      }, 300);
    },
    route(route: string) {
      this.$router.push(route);
    },
  },
  components: {
    CommonInputs
  }
});
