
import { defineComponent } from "vue-demi";
import RegistrationMain from "@/components/auth/Registration.vue";

export default defineComponent({
  name: "RegistrationView",
  components: {
    RegistrationMain,
  },
});
